import React, { useState } from 'react';
import { ImageBackground, Image, StyleSheet, View, Text, TouchableOpacity, ActivityIndicator, Platform } from 'react-native';
import { background, Icons } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles, Fonts } from '../../styles';
import SelectionCard from '../Scan/SelectionCard';
import { Gender } from '../../shared/model/enums';
import ProductService from '../../shared/services/products-service';

const SkiCatalog: React.FC = (): JSX.Element => {
    const [newScanState] = useGlobalState('newScanState');
    const [currentScan] = useGlobalState('currentScan');
    const [currentUser] = useGlobalState('currentUser');
    const [language] = useGlobalState('language');

    const [filterOption, setFilterOption] = useState<"AGE" | "SKILL" | "TERRAIN" | "WHEIGHT">("AGE");
    const [ageValue, setAgeValue] = useState<String | undefined>(undefined);
    const [skillValue, setSkillValue] = useState<String | undefined>(undefined);
    const [terrainValue, setTerrainValue] = useState<String | undefined>(undefined);

    const [isLoading, setLoading] = useState<boolean>(false);
    const [productsFound, setProductsFound] = useState<boolean>(true);
    const i18n = I18N(language);

    const [Title, setTitle] = useState<string>(i18n.t('SkiCatalog.ageGroup'));
    const [question, setQuestion] = useState<string>(i18n.t('SkiCatalog.ageGroupQuestion'));

    const navigation = useNavigation();

    const AGEGROUP_CHOICES = [
        { caption: "kids", value: "kids", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/age-kids.png" },
        { caption: "adults", value: "adults", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/age-adult.png" }
    ]

    const SKILL_CHOICES = [
        { caption: "beginner", value: "beginner", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/skillBeginner.png" },
        { caption: "intermediate", value: "intermediate", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/skillIntermediate.png" },
        { caption: "expert", value: "expert", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/skillExpert.png" },
        { caption: "race", value: "race", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/skillRace.png" }
    ]
    const TERRAIN_CHOICES = [
        { caption: "allmontain", value: "allmountain", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/terrainMontain.png" },
        { caption: "piste", value: "ski-slope", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/terrainPiste.png" },
        { caption: "hard", value: "ice", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/terrainHard.png" },
        { caption: "freeride", value: "freeride", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/terrainFreeRide.png" }
    ]

    const WEIGHT_CHOICES = [
        { caption: "upto60", value: "up-to-60", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/weight_upto60.png" },
        { caption: "60to80", value: "60-to-80", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/weight_60to80.png" },
        { caption: "80to100", value: "80-to-100", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/weight_80to100.png" },
        { caption: "morethan100", value: "above-100", image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/OneFID/SkiCatalog/weigh_morethan100.png" }

    ]

    const navigateBack = () => {
        if (filterOption === "AGE") navigation.goBack()
        if (filterOption === "SKILL") { setFilterOption("AGE"), setTerrainValue(undefined), setTitle(i18n.t('SkiCatalog.ageGroup')), setQuestion(i18n.t('SkiCatalog.ageGroupQuestion')) }
        if (filterOption === "TERRAIN") { setFilterOption("SKILL"), setTerrainValue(undefined), setTitle(i18n.t('SkiCatalog.skill')), setQuestion(i18n.t('SkiCatalog.skillQuestion')) }
        if (filterOption === "WHEIGHT" && productsFound) { setFilterOption("TERRAIN"), setTitle(i18n.t('SkiCatalog.terrain')), setQuestion(i18n.t('SkiCatalog.terrainQuestion')) }
        if (!productsFound) { setProductsFound(true) }

    };

    const selectAgeGroup = () =>
        AGEGROUP_CHOICES.map((choice: any, index: number) => (
            <SelectionCard key={index} caption={i18n.t(`SkiCatalog.${choice.caption}`)}
                image={choice.image}
                onPress={() => [setAgeValue(choice.value), setFilterOption("SKILL"), setTitle(i18n.t('SkiCatalog.skill')), setQuestion(i18n.t('SkiCatalog.skillQuestion'))]} />
        ))

    const selectSkill = () =>
        SKILL_CHOICES.map((choice: any, index: number) => (
            <SelectionCard key={index} caption={i18n.t(`SkiCatalog.${choice.caption}`)}
                image={choice.image}
                onPress={() => [setSkillValue(choice.value), setFilterOption("TERRAIN"), setTitle(i18n.t('SkiCatalog.terrain')), setQuestion(i18n.t('SkiCatalog.terrainQuestion'))]} />
        ))

    const selectTerrain = () =>
        TERRAIN_CHOICES.map((choice: any, index: number) => (
            <SelectionCard key={index} caption={i18n.t(`SkiCatalog.${choice.caption}`)}
                image={choice.image}
                onPress={() => [setTerrainValue(choice.value), setFilterOption("WHEIGHT"), setTitle(i18n.t('SkiCatalog.weight')), setQuestion(i18n.t('SkiCatalog.weightQuestion'))]} />
        ))

    const selectWeight = () =>
        WEIGHT_CHOICES.map((choice: any, index: number) => (
            <SelectionCard key={index} caption={i18n.t(`SkiCatalog.${choice.caption}`)}
                image={choice.image}
                onPress={() => [getSkiProducts(choice.value),
                ]} />
        ))

    const getSkiProducts = (weightValue: any) => {
        const filtredproducts: any[] = [];
        setLoading(true)
        ProductService.getMatchingSkiProducts(
            ageValue,
            skillValue,
            terrainValue,
            weightValue,
            newScanState.gender === Gender.FEMALE
                ? "f"
                : newScanState.gender === Gender.MALE
                    ? "m"
                    : "d",
            currentUser && currentUser.email ? currentUser.email : currentScan.customer_uuid
        )
            .then((products: any) => {
                products.results.map((result: any) => {
                    filtredproducts.push({
                        id: result.id,
                        name: result.product_name,
                        brand: result.brand,
                        gtin: result.gtin,
                        price: result.price,
                        size: {
                            eu: result.mondopoint,
                            uk: result.uk,
                            us: result.us
                        },
                        ...result.image_url && { image_url: result.image_url },
                        ...result.thumb_url > 0 && { thumb_url: result.thumb_url },
                        description: result.description,
                        last_width_category: result.last_width_category,
                        msi: result.msi,
                        leading_system: 'eu'

                    })
                })
                console.log('filtredproducts.length', filtredproducts.length)
                filtredproducts.length > 0
                    ? [
                        navigation.push('CatalogProducts', { runningProducts: filtredproducts }),
                        setLoading(false),
                    ]
                    : [setLoading(false),
                    setProductsFound(false)]
            })
            .catch(error => {
                setLoading(false)
                setProductsFound(false)
                console.log('ERROR getSkiProducts: ', error)
            })
    }

    const reset = () => {
        setAgeValue(undefined)
        setSkillValue(undefined)
        setTerrainValue(undefined)
        setProductsFound(true)
        setFilterOption("AGE")
        setTitle(i18n.t('SkiCatalog.ageGroup'))
        setQuestion(i18n.t('SkiCatalog.ageGroupQuestion'))
    }
    const resetFilter = () =>
        <View style={styles.resetFilterWrapper}>
            <Image source={Icons.NotFoundImage} style={{ width: '50%', height: '50%', resizeMode: 'contain', alignSelf: 'center' }}></Image>
            <View style={{ width: '40%', height: '50%', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <Text style={styles.loadingText}>{i18n.t('SkiCatalog.noProducts')}</Text>
                <Text style={[Fonts.textSmaller, { textAlign: 'center' }]}>{i18n.t('SkiCatalog.filterCriteriaMessage')}</Text>
                <TouchableOpacity style={Styles.submitButtonDark} onPress={() => reset()} >
                    <Text style={[styles.buttonText, { color: '#ffffff' }]}>{i18n.t('SkiCatalog.resetFilter')}</Text>
                </TouchableOpacity>
            </View>
        </View>

    return <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
        <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} title={!isLoading && productsFound ? Title : i18n.t('SkiCatalog.title')}>
            <View style={styles.backButtonWrapper}>
                <TouchableOpacity
                    style={styles.backButton}
                    onPress={() => navigateBack()}>
                    <Image source={Icons.backArrowIcon} style={styles.iconContainer} />
                </TouchableOpacity>
            </View>
            <View style={styles.contentWrapper}>
                {!isLoading && productsFound && <Text style={styles.questiontext}>{question}</Text>}
                <View style={styles.cardWrapper}>
                    {isLoading
                        ? <View style={styles.loadingWrapper}>
                            <Text style={styles.loadingText}>{i18n.t('loadProductMessage')}</Text>
                            <ActivityIndicator size="large" color={'#2bc6ff'} />
                        </View>
                        : !productsFound
                            ? resetFilter()
                            : [
                                filterOption === "AGE" && selectAgeGroup(),
                                filterOption === "SKILL" && selectSkill(),
                                filterOption === "TERRAIN" && selectTerrain(),
                                filterOption === "WHEIGHT" && selectWeight()
                            ]
                    }
                </View>
            </View>
        </LayoutWrapper>
    </ImageBackground>;
};

export default SkiCatalog;

const styles = StyleSheet.create({
    backgroundImage: {
        resizeMode: 'cover',
        width: '100%',
        height: '100%',
        backgroundColor: 'white',
    },
    contentWrapper: {
        flex: 1,
        marginTop: Platform.OS === 'web' ? '0%' : '2%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    cardWrapper: {
        flex: 1,
        width: '80%',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        maxWidth: '80%',
        marginTop: Platform.OS === 'web' ? '.5%' : '3%',
    },
    selectionCaption: {
        fontFamily: 'Montserrat-Black',
        fontSize: 21,
        color: '#3a3a3a',
        marginTop: '3.5%'
    },
    backButtonWrapper: {
        height: Platform.OS === 'web' ? '12%' : '1.3%',
        width: '5.2%',
        position: 'absolute',
        zIndex: 2,
        marginTop: Platform.OS === 'web' ? '1.2%' : '5.8%',
        top: 50,
        marginLeft: '1.6%'
    },
    backButton: {
        flex: 1,
        backgroundColor: '#041b3b',
        justifyContent: 'center',
        alignItems: 'center',
        shadowColor: '#8e8f90',
        opacity: 0.95,
        shadowOpacity: 0.8,
        shadowRadius: 2,
        shadowOffset: {
            height: 1,
            width: 0
        }
    },
    iconContainer: {
        height: '50%',
        width: '50%',
        resizeMode: 'contain'
    },
    loadingText: {
        fontFamily: 'Montserrat-Black',
        fontSize: 19,
        fontWeight: '600',
        textAlign: 'center',
        alignSelf: 'center'
    },
    loadingWrapper: {
        width: '100%',
        height: '35%',
        justifyContent: 'space-around',
        alignSelf: 'center',
    },
    resetFilterWrapper: {
        width: '100%',
        height: '60%',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '10%',
        alignSelf: 'flex-start'
    },
    buttonText: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase'
    },
    questiontext: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 19,
        fontWeight: '600',
        textAlign: 'center',
        alignSelf: 'center',
        width: '75%'
    },
});
