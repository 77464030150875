import React, { useEffect, useState } from 'react';
import { ActivityIndicator, Image, ImageBackground, PixelRatio, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { background } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import ScannerService from '../../shared/services/scanner-service';
import { useGlobalState } from '../../shared/state';
import { I18N, renameKey, sleep } from '../../shared/util';
import { Styles } from '../../styles';
import ScanFailedModal from './ScanFailedModal';
import { Gender } from '../../shared/model/enums';

const leftFoot = require('../../assets/images/scan/leftFoot.png');
const rightFoot = require('../../assets/images/scan/rightFoot.png');

const ScanBothFeet: React.FC = (): JSX.Element => {
  const [, setCurrentScreen] = useGlobalState('currentScreen');
  const [, setScanningDATA] = useGlobalState('scanningDATA');

  const [currentScanner] = useGlobalState('currentScanner');
  const [newScanState] = useGlobalState('newScanState');
  const [language] = useGlobalState('language');
  const [scanfiles, setScanfiles] = useGlobalState('scanfiles');
  const [currentUser] = useGlobalState('currentUser');
  const [userOrigin] = useGlobalState('userOrigin');
  const [userBirthDate] = useGlobalState('userBirthDate');
  const [Colors] = useGlobalState('colors');

  const i18n = I18N(language);

  const [scanInProgress, setScanInProgress] = useState<boolean>(false);
  const [scanningMessage, setScanScanningMessage] = useState<string>(i18n.t('scan.setParameter'));
  const [scanProcessResult, setScanProcessResult] = useState<'SUCCESS' | 'FAILURE'>();
  const [scanParameter, setScanParameter] = useState<any>({
    scannedObject: undefined,
    side: undefined,
    returnType: undefined
  });
  const [errorCode, setErrorCode] = useState<string>("");

  const navigation = useNavigation();
  const keysMapReplacement = {
    archHeight: "arch_height",
    heelAngle: "heel_angle",
    heelAngleH: "heel_angle_heel",
    heelAngleT: 'heel_angle_tendon',
    footType: 'foot_type'
  }
  const mode = newScanState.mode.toLowerCase() || 'socks';

  useEffect(() => {
    getScanParameter();
  }, []);

  useEffect(() => {
    const routeName = navigation.getState().routeNames
    if (scanfiles.length === 2 && routeName.includes('ScanBothFeet'))
      navigate("ScanSuccessfulModal")
  }, [scanfiles]);

  useEffect(() => {
    const date: any = new Date();
    if (Object.keys(scanfiles).length > 0) {
      setScanningDATA({
        scandate: date.toISOString(),
        fileformat: 'ply',
        scanmode: mode.toLowerCase().includes('socks') ? 'socks' : 'barefoot',
        scanfiles: scanfiles,
        customer: {
          ...(currentUser && {
            ...(currentUser.email
              ? { email: currentUser.email }
              : { employee_number: currentUser.employeeNumber }),
          }),
          ...(newScanState.gender === Gender.FEMALE && { gender: 'f' }),
          ...(newScanState.gender === Gender.MALE && { gender: 'm' }),
          ...(userOrigin && { continent: userOrigin }),
          ...(userBirthDate && { birth_date: userBirthDate }),
        },
      });
    }
  }, [scanfiles]);

  const navigate = (route: string | undefined, options: any = undefined) => {
    if (route) {
      navigation.navigate(route, options);
      setCurrentScreen(route);
    }
  };

  const reset = () => {
    setScanningDATA(undefined);
    setScanfiles([]);
    setScanProcessResult(undefined);
    setScanInProgress(false);
  };

  const getScanParameter = () => {
    setScanParameter({
      scannedObject: mode,
      side: 'both',
      returnType: 'base64'
    });
  };

  const getScan = () => {
    if (scanParameter) {
      setScanInProgress(true);
      sleep(7000).then(() => {
        setScanScanningMessage(i18n.t('scan.capture'))
        sleep(8500).then(() => setScanScanningMessage(i18n.t('scan.process'))
        )
      })
      ScannerService.getScan(currentScanner['scanner-address'], scanParameter)
        .then((scanResult: any) => {
          setScanProcessResult('SUCCESS');
          var ScanResultArray: any = scanResult.map((
            {
              InsoleRecommendation: insole_recommendation,
              rawMeasurements: raw_measurements,
              categorizedMeasurements: categorized_measurements,
              plantarColorImage: plantar_image,
              PlantarImage: real_color_plantar_image = "",
              colouredSole: colored_sole,
              ...reset
            }) => ({
              insole_recommendation,
              raw_measurements,
              categorized_measurements,
              plantar_image,
              real_color_plantar_image,
              colored_sole,
              ...reset
            }))

          ScanResultArray.map((scan: any) => {
            scan.condition = 'standing'
            scan.categorized_measurements = renameKey(scan.categorized_measurements, keysMapReplacement)
            scan.raw_measurements = renameKey(scan.raw_measurements, keysMapReplacement)
          })
          setScanfiles(ScanResultArray)

          // const rightButton = {
          //   text: 'next',
          //   navigateTo: 'ScanSuccessfulModal'
          // };
          // setFooterMenu((prev: any) => ({ ...prev, rightButton: rightButton }));
        })
        .catch((error: any) => {
          console.log('error', error);
          if (error.response_code === 404) {
            setErrorCode('scannerBusy')
          } else {
            if (Array.isArray(error.message.errors)) {
              setErrorCode(error.message.errors[0].error_code)
            } else if (error.message && error.message.includes('foam')) {
              setErrorCode('foamBoxNotImplemented')
            } else {
              setErrorCode('genericError')
            }
          }
          setScanInProgress(false);
          setScanProcessResult('FAILURE');
        });
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showBackButton={scanInProgress ? false : true} showMenuButtons={false} hideNewScanButton hideLeftButton >
        <View style={styles.contentWrapper}>
          {scanInProgress && <>
            <View style={styles.processingWrapper}>
              <Text style={styles.processingText}>{scanningMessage}</Text>
              <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
            </View>
          </>}
          {!scanInProgress && (!scanProcessResult || scanProcessResult !== 'FAILURE') && <>
            <View style={styles.headerRow}>
              <Text style={styles.headerText}>{i18n.t('scan.captionMulti')}</Text>
            </View>
            <TouchableOpacity style={styles.contentBox} activeOpacity={.6} onPress={getScan}>
              <View style={styles.captionContainer}>
                <Text style={styles.start}>{i18n.t('scan.start')}</Text>
              </View>
              <View style={styles.imageContainer}>
                <Image source={leftFoot} style={styles.image} />
                <Image source={rightFoot} style={styles.image} />
              </View>
              <View style={styles.captionContainer}>
                <Text style={styles.caption}>{i18n.t('scan.both')}</Text>
              </View>
            </TouchableOpacity>
          </>}
          {!scanInProgress && scanProcessResult && scanProcessResult === 'FAILURE' && <>
            <ScanFailedModal errorCode={errorCode} onScanAgain={() => reset()} onCancel={() => navigate('Home')} />
          </>}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ScanBothFeet;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  headerRow: {
    maxHeight: '15%',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  headerText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    marginTop: '1.5%'
  },
  contentBox: {
    backgroundColor: '#ffffff',
    opacity: .9,
    width: '50%',
    maxHeight: '75%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 12 / PixelRatio.get(),
    margin: '1.5%',
    shadowColor: '#000',
    shadowOffset: { width: 6 / PixelRatio.get(), height: 6 / PixelRatio.get() },
    shadowOpacity: .16,
    shadowRadius: 4 / PixelRatio.get(),
    elevation: 1
  },
  imageContainer: {
    height: '65%',
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: '100%',
    width: '50%',
    maxWidth: '75%',
    resizeMode: 'contain',
    marginTop: '2%'
  },
  captionContainer: {
    height: '10%',
    marginTop: '7%'
  },
  caption: {
    fontFamily: 'Montserrat-Black',
    fontSize: 18,
    color: '#3a3a3a'
  },
  start: {
    fontFamily: 'Montserrat-SemiBold',
    fontSize: 18,
    color: '#3a3a3a'
  },
  processingWrapper: {
    minHeight: '90%',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  processingText: {
    fontFamily: 'Montserrat-Black',
    fontSize: 30,
    marginBottom: 15
  },
  checkmark: {
    maxHeight: '75%',
    maxWidth: '75%',
    resizeMode: 'contain',
  }
});
