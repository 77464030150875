import ProductService from "../../shared/services/products-service";

// Function to fetch products based on provided parameters
export const fetchProducts = (
  customer_uuid: string,
  lastwidthcategoryMode: string,
  gender?: string,
  storeID?: string,
  showPartnerProducts?: boolean
) => {

  const genderArray =
    gender === "d" ? ["u", "f", "m"] : ["u", gender];

  const data = showPartnerProducts
    ? {
      onefid: customer_uuid,
      lastwidthcategory_mode: lastwidthcategoryMode,
      gender: genderArray,
    }
    : {
      onefid: customer_uuid,
      lastwidthcategory_mode: lastwidthcategoryMode,
      gender: genderArray,
      store_id: storeID,
    };

  return ProductService.getMatchingProducts(data);
};

// Function to process and filter the fetched products
export const processAndFilterProducts = (
  response: any,
  setFullCatalog: (product: any) => void,
  attributeName: any,
  safetyClass?: any,
  storeID?: string,
  showPartnerProducts?: boolean,
  showZeroStockProducts?: boolean,
  onComplete?: () => void
) => {
  let filtredArray: any[] = [];
  const matchedProductGtins = response.results.matching
    .filter((p1: any) => !!p1.gtin)
    .map((p1: any) => p1.gtin);

  response.results.products.forEach((product: any) => {
    product.details.forEach((detail: any) => {
      if (matchedProductGtins.includes(detail.gtin)) {
        let secondary_eu = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_eu;
        let secondary_uk = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_uk;
        let secondary_us = response.results.matching.find((product: any) => product.gtin === detail.gtin).secondary_us;

        filtredArray.push({
          id: product.category_info.id,
          name: product.category_info.name,
          attributes: product[attributeName],
          products: {
            id: product.id,
            name: product.name,
            categoryId: product.category_info.id,
            categoryName: product.category_info.name,
            brand: product.brand,
            gtin: detail.gtin,
            mpn: product.mpn,
            safety_class: product.safety_class,
            price: detail.price,
            salePrice: detail.sale_price,
            color: product.color,
            storeID: detail.store_id,
            stock: detail.stock,
            projectedStock: detail.projected_stock,
            warehouseBin: detail.warehouse_bin,
            leatherType: product.leather_type,
            support: product.support,
            underground: product.underground,
            suitableOrthoAdaption: product.suitable_ortho_adaption,
            suitableOrthoInsole: product.suitable_ortho_insole,
            suitableStandardInsole: product.suitable_standard_insole,
            application: product.application,
            ...(product.images[0] && { image_url: product.images[0].original }),
            ...(product.images[0] && { thumb_url: product.images[0].thumb }),
            size: {
              eu: response.results.matching.find((product: any) => product.gtin === detail.gtin).eu,
              uk: response.results.matching.find((product: any) => product.gtin === detail.gtin).uk,
              us: response.results.matching.find((product: any) => product.gtin === detail.gtin).us,
              ...(secondary_eu && { secondary_eu }),
              ...(secondary_uk && { secondary_uk }),
              ...(secondary_us && { secondary_us })
            },
            description: product.description,
            last_width_category: product.last_width_category,
            width_name: product.width_name,
            msi: product.msi,
            leading_system: response.results.matching.find((product: any) => product.gtin === detail.gtin).leading_system,
            ...response.results.matching.find((product: any) => product.gtin === detail.gtin).leading_system
          }
        });
      }
    });
  });

  if (safetyClass && attributeName === "safety_class") filtredArray = filtredArray.filter((p: any) => p.attributes === safetyClass);
  if (!showPartnerProducts && storeID) filtredArray = filtredArray.filter((p: any) => p.products.storeID === storeID);
  if (!showZeroStockProducts) filtredArray = filtredArray.filter((p: any) => p.products.stock !== 0);
  if (onComplete) onComplete();

  setFullCatalog(filtredArray)
};
