export enum Gender {
  FEMALE = 'female',
  MALE = 'male',
  DIVERSE = 'diverse'
}

export enum Mode {
  DARK_SOCKS = 'dark_socks',
  BRIGHT_SOCKS = 'bright_socks',
  SOCKS = 'socks',
  BAREFOOT = 'barefoot'
}