import React, { useEffect } from 'react';
import { ImageBackground, StyleSheet, View } from 'react-native';
import { background, genderFemale, genderMale, genderDiverse } from '../../assets';
import LayoutWrapper from '../../components/LayoutWrapper/LayoutWrapper';
import { useNavigation } from '@react-navigation/native';
import { Gender } from '../../shared/model/enums';
import { NewScanStateDTO } from '../../shared/model/types';
import { useGlobalState } from '../../shared/state';
import { I18N } from '../../shared/util';
import { Styles } from '../../styles';
import SelectionCard from './SelectionCard';

const NewScanGenderSelection: React.FC<any> = ({ route, navigation }): JSX.Element => {
  const [currentScanner] = useGlobalState('currentScanner');
  const [, setState] = useGlobalState('newScanState');
  const [, setRoute] = useGlobalState('currentScreen');
  const [, setUserOrigin] = useGlobalState('userOrigin');
  const [language] = useGlobalState('language');

  const i18n = I18N(language);
  const nav = useNavigation();
  const {resetState} = route.params;

  const navigate = (route: string | undefined) => {
    if (route) {
      nav.navigate(route);
      setRoute(route);
    }
  };

  useEffect(() => {
    if (resetState) setState({ gender: undefined, mode: undefined });
  }, []);

  const onSelect = (choice: Gender) => {
    setUserOrigin(undefined)
    setState((prevState: NewScanStateDTO) => ({ ...prevState, gender: choice }));
    navigate(currentScanner.userOrigin ? 'NewScanUserOriginSelection' : 'NewScanModeSelection');
  };

  return <ImageBackground source={background} style={Styles.backgroundImage} blurRadius={15}>
    <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={false} showMenuButtons={false} showStartButton={false} hideNewScanButton showBackButton={true} title={i18n.t('gender.caption')}>
      <View style={styles.contentWrapper}>
        <View style={styles.cardWrapper}>
          <SelectionCard value={i18n.t(`gender.${Gender.FEMALE}`).toUpperCase()}
            image={genderFemale}
            onPress={() => onSelect(Gender.FEMALE)} />
          <SelectionCard value={i18n.t(`gender.${Gender.MALE}`).toUpperCase()}
            image={genderMale}
            onPress={() => onSelect(Gender.MALE)} />
          <SelectionCard value={i18n.t(`gender.${Gender.DIVERSE}`).toUpperCase()}
            image={genderDiverse}
            onPress={() => onSelect(Gender.DIVERSE)} />
        </View>
      </View>
    </LayoutWrapper>
  </ImageBackground>;
};

export default NewScanGenderSelection;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardWrapper: {
    flex: 1,
    width: '70%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    maxWidth: '70%',
    marginTop: '3%',
  },
  selectionCaption: {
    fontFamily: 'Montserrat-Black',
    fontSize: 21,
    fontWeight: '900',
    color: '#3a3a3a',
    marginTop: '3.5%'
  }
});
