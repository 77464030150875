import React, { useEffect, useState } from "react";
import { View, Text, StyleSheet, Image, TouchableOpacity, ImageBackground } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { Picker } from "@react-native-picker/picker";
import { Icons, background } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { Styles } from "../../styles";
import { useGlobalState } from "../../shared/state";
import { I18N } from "../../shared/util";
import ProductService from "../../shared/services/products-service";
import { getMatchedproducts } from "../../shared/products";
import { Gender } from "../../shared/model/enums";

const ChooseCustomer: React.FC = (): JSX.Element => {
  const navigation = useNavigation();
  const [, setMatchedProducts] = useGlobalState("matchedProducts");
  const [, setInitialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [, setAllProducts] = useGlobalState("allProducts");
  const [currentScan] = useGlobalState("currentScan");
  const [newScanState] = useGlobalState("newScanState");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [language] = useGlobalState("language");
  const i18n = I18N(language);
  const [Colors] = useGlobalState("colors");
  const [customerStoreID, setCustomerStoreID] = useGlobalState("customerStoreID");
  const [, setIndustryPartner] = useGlobalState("industryPartner");
  const [stores, setStores] = useState<any[]>([]);
  const [storesPickerItems, setStoresPickerItems] = useState<React.ReactNode[]>([]);
  const [selectedStore, setSelectedStore] = useState<string>(customerStoreID || "");

  useEffect(() => {
    (async () => {
      const response = await ProductService.getStores();
      setStores(response.results);
    })();
  }, []);

  useEffect(() => {
    let customersArray: JSX.Element[] = [];

    stores.forEach((customer: any) => {
      customersArray.push(<Picker.Item key={customer.id} label={customer.name} value={customer.store_id} />);
    });

    // Only set the default selected store if customerStoreID is not already set
    if (!customerStoreID && stores[0]) {
      setSelectedStore(stores[0].store_id);
    }

    setStoresPickerItems(customersArray);
  }, [stores, customerStoreID]);

  const handleSubmit = async () => {
    setCustomerStoreID(selectedStore);
    const industryPartner = stores.find((store: any) => store.store_id === selectedStore);
    setIndustryPartner(industryPartner);
    
    if (currentScan || currentUser) {
      const storeID = selectedStore;
      const gender = newScanState.gender === Gender.FEMALE
      ? "f"
      : newScanState.gender === Gender.MALE
      ? "m"
      : "d";
      const customer_uuid = currentScan ? currentScan.customer_uuid : currentUser.uuid;
      const lastWidthCategoryMode = currentScanner.lastWidthCategoryMode || "default";
      const safetyClass = currentUser?.safetyClass;
      const showPartnerProducts = currentScanner["showPartnerProducts"];
      const showZeroStockProducts = currentScanner["showZeroStockProducts"];

      getMatchedproducts(
        setAllProducts,
        setMatchedProducts,
        setInitialMatchedProducts,
        lastWidthCategoryMode,
        customer_uuid,
        currentScanner["attribute_name"],
        gender,
        safetyClass,
        storeID,
        showPartnerProducts,
        showZeroStockProducts
      );
      navigation.navigate("MainApp", { screen: "Measurements" });
    } else {
      navigation.navigate("MainApp", { screen: "Home" });
    }
  };

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton showHeaderLogo showBackButton={false}>
        <View style={styles.container}>
          <Text style={styles.titleText}>{i18n.t("chooseCustomer")}</Text>
          <Image source={Icons.support.corporate} style={styles.iconContainer} />
          <View style={styles.inputsWrapper}>
            <Picker style={styles.picker} selectedValue={selectedStore} onValueChange={(itemValue) => setSelectedStore(itemValue)}>
              {storesPickerItems}
            </Picker>
          </View>
          <TouchableOpacity style={[styles.button, { backgroundColor: Colors.mainColorDark.color }]} onPress={handleSubmit}>
            <Text style={styles.buttonText}>{i18n.t("chooseButton")}</Text>
          </TouchableOpacity>
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default ChooseCustomer;

const styles = StyleSheet.create({
  container: {
    width: "70%",
    height: "90%",
    alignSelf: "center",
    backgroundColor: "white",
    padding: "5%",
  },
  formWrapper: {
    width: "100%",
    height: "75%",
    alignSelf: "center",
  },
  inputsWrapper: {
    width: "95%",
    height: "25%",
    borderRadius: 6,
    alignItems: "center",
    alignSelf: "center",
  },
  iconContainer: {
    width: "10%",
    height: "10%",
    resizeMode: "contain",
    alignSelf: "center",
    margin: "2%",
  },
  titleText: {
    fontFamily: "Montserrat-Black",
    fontSize: 21,
    fontWeight: "900",
    textAlign: "center",
    textTransform: "uppercase",
    margin: "2%",
  },
  picker: {
    width: "90%",
    height: "50%",
    backgroundColor: "#f0eff1",
    borderRadius: 6,
    // marginLeft: "5%",
  },
  button: {
    width: 195,
    height: 42,
    borderRadius: 17,
    justifyContent: "center",
    alignItems: "center",
    alignSelf: "center",
  },
  buttonText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 12,
    textAlign: "center",
    color: "#ffffff",
    textTransform: "uppercase",
  },
});
