import React, { useState, useEffect } from "react";
import { ImageBackground, StyleSheet, ActivityIndicator, Image, Text, TouchableOpacity, View } from "react-native";
import { useNavigation, useFocusEffect } from "@react-navigation/native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import { background, Icons } from "../../assets";
import LayoutWrapper from "../../components/LayoutWrapper/LayoutWrapper";
import { useGlobalState } from "../../shared/state";
import { getValidIndex, groupByKey, I18N } from "../../shared/util";
import { Styles, Fonts } from "../../styles";
import CategoryCard from "./CategoryCard";
import { CategoryCardDTO } from "../../shared/model/types";
import I18n from "../../i18nSets/i18n";
import { Gender } from "../../shared/model/enums";
import { fetchProducts, processAndFilterProducts } from "./Utils";
import { insoleCategories } from "../InsoleRecommendation/InsoleRecommendation";
import ProductService from "../../shared/services/products-service";

const PreFilterCatalog: React.FC<any> = (route): JSX.Element => {
  const { params } = route.route;
  const [language] = useGlobalState("language");
  const [matchedProducts, setMatchedProducts] = useGlobalState("matchedProducts");
  const [currentScanner] = useGlobalState("currentScanner");
  const [currentUser] = useGlobalState("currentUser");
  const [currentScan] = useGlobalState("currentScan");
  const [newScanState] = useGlobalState("newScanState");
  const [currentMeasurements] = useGlobalState("currentMeasurements");
  const [initialMatchedProducts] = useGlobalState("initialMatchedProducts");
  const [categoryCards, setCategoryCards] = useState<any>([]);
  const [Colors] = useGlobalState("colors");
  const [, setIsOrthoOrder] = useGlobalState("isOrthoOrder");
  const [, setProductStoreID] = useGlobalState("productStoreID");
  const [, setIsFullCatalog] = useGlobalState("isFullCatalog");
  const [toggleOrthoInsoleCheckBox, setToggleOrthoInsoleCheckBox] = useState(false);
  const [toggleShoeFittingCheckBox, setToggleShoeFittingCheckBox] = useState(false);
  const [toggleFullCatalogCheckBox, setToggleFullCatalogCheckBox] = useState(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [fullCatalog, setFullCatalog] = useState<any>([]);
  const [hoffmanProducts, setHoffmanProducts] = useState<any>([]);
  const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
  const [hoffmannStoreFilters, setHoffmannStoreFilters] = useState<any[]>([]);
  const isHoffmann = currentScanner.partner && currentScanner.partner?.toLowerCase() === "hoffmann group";
  const [selectedHoffmannStore, setSelectedHoffmannStore] = useState<string | null>(isHoffmann && currentScanner && currentScanner.storeid);
  const isSSV = currentScanner.partner && currentScanner.partner?.toLowerCase() === "ssv";

  const i18n = I18N(language);
  const navigation = useNavigation();

  const resetFilters = () => {
    toggleOrthoInsoleCheckBox && setToggleOrthoInsoleCheckBox(!toggleOrthoInsoleCheckBox);
    toggleShoeFittingCheckBox && setToggleShoeFittingCheckBox(!toggleShoeFittingCheckBox);
  };

  const insoleCategoryIndex = currentMeasurements
    ? Math.min(getValidIndex(currentMeasurements.measurements[0].insole_recommendation), getValidIndex(currentMeasurements.measurements[1].insole_recommendation)) === Infinity
      ? 1 // If both indices are -1 return 1
      : Math.min(getValidIndex(currentMeasurements.measurements[0].insole_recommendation), getValidIndex(currentMeasurements.measurements[1].insole_recommendation))
    : 1;
  const gender = currentUser ? currentUser.gender : newScanState.gender === "male"
    ? "m"
    : newScanState.gender === "female"
      ? "f"
      : "d";
  const genderForInsoleFilter = gender === "f" ? "female" : "unisex";
  const recommendedInsoleCategory = insoleCategories[insoleCategoryIndex];
  const matchedInsole =
    currentScanner.insoleRecommendation &&
    currentScanner.insoleRecommendation.insoles[genderForInsoleFilter] &&
    currentScanner.insoleRecommendation.insoles[genderForInsoleFilter][recommendedInsoleCategory];

  // Function to apply filters
  const applyFilters = React.useCallback(() => {
    let productsToShow = toggleFullCatalogCheckBox ? fullCatalog : selectedHoffmannStore ? hoffmanProducts : matchedProducts;

    if (toggleOrthoInsoleCheckBox) {
      productsToShow = productsToShow.filter((product) => product.products.suitableOrthoInsole);
    }

    if (toggleShoeFittingCheckBox) {
      productsToShow = productsToShow.filter((product) => product.products.suitableOrthoAdaption);
    }
    console.log("# products after filters ====", productsToShow && productsToShow.length);
    updateCategoryCards(productsToShow); // Update category cards based on filtered products
    setFilteredProducts(productsToShow);
  }, [toggleOrthoInsoleCheckBox, toggleShoeFittingCheckBox, toggleFullCatalogCheckBox, fullCatalog, matchedProducts, hoffmanProducts]);

  const ssvColorCategories = [
    {
      id: I18n.t("white", { locale: navigator.language }),
      name: "white",
      image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/Steitz/work-shoes-white.png",
    },
    {
      id: I18n.t("black", { locale: navigator.language }),
      name: "black",
      image: "https://onefid-core-prod.s3.eu-central-1.amazonaws.com/partners/Steitz/work-shoes.png",
    },
  ];

  // Function to update category cards
  const updateCategoryCards = (productsToShow: any[]) => {
    setCategoryCards([]);

    // Populate main category cards based on filtered products
    if (isSSV && currentScanner.enableColorCatalog) {
      ssvColorCategories.forEach((category, index) => {
        for (let categoryID of Object.keys(groupByKey(productsToShow, "color"))) {
          if (categoryID.toLocaleLowerCase() === category.id.toLocaleLowerCase()) {
            setCategoryCards((prevState) => [...prevState, <CategoryCard key={index} CategoryCard={category} onPress={() => nav(category, productsToShow)} />]);
            break;
          }
        }
      });
    } else if (productsToShow && productsToShow.length > 0) {
      currentScanner.catalog.categories.forEach((category, index) => {
        for (let categoryID of Object.keys(groupByKey(productsToShow, "id"))) {
          if (categoryID === category.id) {
            setCategoryCards((prevState) => [...prevState, <CategoryCard key={index} CategoryCard={category} onPress={() => nav(category, productsToShow)} />]);
            break;
          }
        }
      });
    }

    if (
      // toggleFullCatalogCheckBox &&
      currentScanner.partner?.toLowerCase() === "elten" &&
      matchedInsole
    ) {
      setCategoryCards((prevState) => [
        ...prevState,
        <TouchableOpacity key="accessories" style={styles.touchContainer} activeOpacity={0.6} onPress={() => navigation.push("AccessoriesCatalog", { insoles: matchedInsole })}>
          <View style={Styles.card}>
            <View style={styles.categoryCard}>
              <Image source={{ uri: Icons.soleIcon }} style={styles.imageContainer} />
            </View>
            <View style={styles.categoryCardTextWrapper}>
              <Text style={styles.categoryCardText}>{i18n.t("accessoriesCatalogTitle")}</Text>
            </View>
          </View>
        </TouchableOpacity>,
      ]);
    }
  };

  useEffect(() => {
    if (params && params.isOrthoOrder === false) {
      setIsOrthoOrder(false);
    }
  }, [params]);

  useEffect(() => {
    if (toggleFullCatalogCheckBox) {
      setIsLoadingProducts(true);
      setIsFullCatalog(true);
      const gender = newScanState.gender === Gender.FEMALE
      ? "f"
      : newScanState.gender === Gender.MALE
      ? "m"
      : "d";    
      const customer_uuid = currentScan ? currentScan.customer_uuid : currentUser.uuid;
      const safetyClass = currentUser?.safetyClass;

      fetchProducts(customer_uuid, "loose", gender, "elten").then((response: any) => {
        setProductStoreID("elten");
        processAndFilterProducts(response, setFullCatalog, currentScanner["attribute_name"], safetyClass, "elten", undefined, undefined, () => setIsLoadingProducts(false));
      });
    } else if (initialMatchedProducts) {
      setProductStoreID("");
      setIsFullCatalog(false);
      setMatchedProducts(initialMatchedProducts);
    }
  }, [toggleFullCatalogCheckBox]);


  const handleSelectingHoffmannStore = (store: string) => {
    setSelectedHoffmannStore(store);
    setProductStoreID(store);
  }

  useEffect(() => {
    if (selectedHoffmannStore) {
      setIsLoadingProducts(true);
      const gender = newScanState.gender === Gender.FEMALE
      ? "f"
      : newScanState.gender === Gender.MALE
      ? "m"
      : "d";
      const customer_uuid = currentScan ? currentScan.customer_uuid : currentUser.uuid;
      const safetyClass = currentUser?.safetyClass;

      fetchProducts(customer_uuid, "loose", gender, selectedHoffmannStore).then((response: any) => {
        setProductStoreID(selectedHoffmannStore);
        processAndFilterProducts(response, setHoffmanProducts, currentScanner["attribute_name"], safetyClass, selectedHoffmannStore, undefined, undefined, () => setIsLoadingProducts(false));
      });
    } else if (initialMatchedProducts) {
      setProductStoreID("");
      setMatchedProducts(initialMatchedProducts);
    }
  }, [selectedHoffmannStore])

  // Apply filters when component gains focus
  useFocusEffect(
    React.useCallback(() => {
      setMatchedProducts(initialMatchedProducts); // Reset matched products
      applyFilters(); // Apply filters
    }, [applyFilters])
  );

  useEffect(() => {
    applyFilters(); // Reapply filters on dependency changes
  }, [fullCatalog, matchedProducts, toggleOrthoInsoleCheckBox, toggleShoeFittingCheckBox, hoffmanProducts]);

  useEffect(() => {
    (async () => {
      const response = await ProductService.getStores();
      response.results.map((store: any) => {
        setHoffmannStoreFilters((prevFilters: any) => [
          ...prevFilters,
          { name: store.name, store_id: store.store_id }
        ]);
      });
    })();
  }, []);


  const nav = (category: CategoryCardDTO, productsToShow: any) => {
    setMatchedProducts(productsToShow);
    if (category.attributes) {
      navigation.push("CatalogTree", { category, matchedInsole });
    } else {
      navigation.navigate("CatalogProducts", { categoryID: category.id });
    }
  };

  // const hoffmannStoreFilters = ["Elten", "Uvex", "Steitz", "Secura", "Garant", "Holex", "All"];

  return (
    <ImageBackground source={background} style={Styles.backgroundImage} imageStyle={{ opacity: 0.4 }}>
      <LayoutWrapper showHeaderCloseButton={false} showHeaderLogo={true} showBackButton={true} title={i18n.t("catalog")}>
        <View style={styles.contentWrapper}>
          {filteredProducts && filteredProducts.length === 0 && !isLoadingProducts && (
            <View style={styles.noProductsWrapper}>
              <Image source={Icons.NotFoundImage} style={{ width: "50%", height: "50%", resizeMode: "contain", alignSelf: "center" }}></Image>
              <Text style={styles.loadingText}>{i18n.t("noProductFoundMessage")}</Text>
              <TouchableOpacity style={Styles.submitButtonLight} onPress={() => navigation.navigate("NewScanGenderSelection", { resetState: true })}>
                <Text style={[styles.buttonText, { ...Colors.black }]}>{i18n.t("new scan")}</Text>
              </TouchableOpacity>
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten" && (
                <TouchableOpacity style={[Styles.submitButtonLight, { backgroundColor: Colors.mainColorDark.color }]} onPress={() => resetFilters()}>
                  <Text style={[styles.buttonText, { ...Colors.white }]}>{i18n.t("resetFilter")}</Text>
                </TouchableOpacity>
              )}
            </View>
          )}
          {filteredProducts && filteredProducts.length > 0 && !isLoadingProducts && (
            <>
              <View style={styles.cardWrapper}>{categoryCards.length > 0 && categoryCards}</View>
              {/* Extra filtering for ELTEN */}
              {currentScanner && currentScanner.partner && currentScanner.partner?.toLowerCase() === "elten" && (
                <View style={styles.filtersWrapper}>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleOrthoInsoleCheckBox}
                      onPress={() => setToggleOrthoInsoleCheckBox(!toggleOrthoInsoleCheckBox)}
                      text={i18n.t("orthopedicInsole")}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleShoeFittingCheckBox}
                      onPress={() => setToggleShoeFittingCheckBox(!toggleShoeFittingCheckBox)}
                      text={i18n.t("shoeFitting")}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                  <View style={styles.checkboxWrapper}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={toggleFullCatalogCheckBox}
                      onPress={() => setToggleFullCatalogCheckBox(!toggleFullCatalogCheckBox)}
                      text={i18n.t("fullCatalog")}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                </View>
              )}
            </>
          )}
          {isHoffmann && (
            <View style={styles.storesFilterWrapper}>
              {hoffmannStoreFilters && hoffmannStoreFilters.map((store: any) => {
                return (
                  <View style={styles.checkboxWrapper} key={store.store_id}>
                    <BouncyCheckbox
                      innerIconStyle={{ borderColor: Colors.mainColorLight.color }}
                      fillColor={Colors.mainColorLight.color}
                      isChecked={selectedHoffmannStore === store.store_id}
                      disableBuiltInState={true}
                      onPress={() => handleSelectingHoffmannStore(store.store_id)}
                      text={store.name}
                      textStyle={styles.checkBoxText}
                    />
                  </View>
                );
              })}
            </View>
          )}
          {isLoadingProducts && (
            <View style={styles.loadingWrapper}>
              <Text style={styles.loadingText}>{i18n.t("loadProductMessage")}</Text>
              <ActivityIndicator size="large" color={Colors.mainColorLight.color} />
            </View>
          )}
        </View>
      </LayoutWrapper>
    </ImageBackground>
  );
};

export default PreFilterCatalog;

const styles = StyleSheet.create({
  contentWrapper: {
    flex: 1,
    marginTop: "2.2%",
    alignItems: "center",
  },
  cardWrapper: {
    width: "81%",
    height: "75%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "0.4%",
    paddingLeft: "0.2%",
  },
  filtersWrapper: {
    width: "25%",
    height: "25%",
    flexDirection: "row",
    flexWrap: "wrap",
    paddingTop: "0.4%",
    paddingLeft: "0.2%",
    alignSelf: "baseline",
    marginLeft: "10%",
  },
  storesFilterWrapper: {
    display: "flex",
    flexDirection: "column",
    position: 'absolute',
    top: "75%",
    width: "33%",
    height: "25%",
    flexWrap: "wrap",
    paddingLeft: "0.1%",
    alignSelf: "baseline",
    marginLeft: "10%",
  },
  titeText: {
    ...Fonts.textHuge,
    ...Fonts.center,
    textTransform: "uppercase",
  },
  loadingText: {
    fontFamily: "Montserrat-SemiBold",
    fontSize: 19,
    fontWeight: "600",
    textAlign: "center",
    alignSelf: "center",
  },
  noProductsWrapper: {
    width: "100%",
    height: "65%",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  loadingWrapper: {
    width: "100%",
    height: "35%",
    justifyContent: "space-around",
    alignSelf: "center",
  },
  buttonText: {
    ...Fonts.mRegular,
    ...Fonts.size12,
    ...Fonts.center,
    textTransform: "uppercase",
  },
  checkboxWrapper: {
    // width: "95%",
    marginBottom: "1%",
    // flexDirection: "row",
  },
  checkBoxText: {
    fontFamily: "Montserrat-Regular",
    fontSize: 18,
    color: "#000000",
    fontWeight: "300",
    textDecorationLine: "none",
  },
  imageContainer: {
    flex: 1,
    height: undefined,
    width: undefined,
    resizeMode: "contain",
  },
  touchContainer: {
    height: "49%",
    width: "33%",
    backgroundColor: "transparent",
    paddingTop: 2,
    paddingLeft: 2,
  },
  categoryCard: {
    height: "70%",
    backgroundColor: "white",
  },
  categoryCardTextWrapper: {
    height: "30%",
    justifyContent: "center",
    alignItems: "center",
  },
  categoryCardText: {
    fontFamily: "Montserrat-Black",
    fontSize: 16,
    textTransform: "uppercase",
  },
});
